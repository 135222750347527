import DataPhotos from "../Fake/DB.json";
import Masonry from "react-masonry-css";
import { IKImage, IKContext, IKUpload } from "imagekitio-react";

const urlEndpoint = "<YOUR_IMAGEKIT_URL_ENDPOINT>";

const Columns = {
  default: 4,
  1200: 3,
  1000: 2,
  700: 1,
};

const Cards = ({ setSelectedImg }) => {
  return (
    <div className="center-masonry">
      <Masonry
        breakpointCols={Columns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {DataPhotos.map(({ id, src }) => {
          return (
            <div onClick={() => setSelectedImg(id)} key={id}>
              <img src={src} alt="" />
            </div>
          );
        })}
      </Masonry>
    </div>
  );
};
export default Cards;
