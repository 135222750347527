import { BrowserRouter, Routes, Route } from "react-router-dom";
import init from "./init.js";
import useScrollPosition from "./components/useScrollPos";
import PrimarySearchAppBar from "./components/AppBar";
import Container from "@mui/material/Container";
import "./app.scss";

//import Layout from "./routerExample/Layout";
import Home from "./routerExample/Home";
import Blogs from "./routerExample/Blogs";
import Contact from "./routerExample/Contact";
import NoPage from "./routerExample/NoPage";

import * as crypto from "crypto";

/*
  handleChange() {
    // Previously changed? Revert back to initial state
    if (this.initialState) {
      this.setState(this.initialState);
      delete this.initialState; // Capture initial state and update
    } else {
      this.initialState = this.state;
      this.setState({
        breakpointColumnsObj: {
          default: 5,
          1100: 4,
          700: 3,
          500: 2,
        },
      });
    }
  }
*/

export default function App() {
  console.log(useScrollPosition());

  return (
    <div className="App">
      <BrowserRouter>
        <PrimarySearchAppBar />
        <Container>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Home />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </Container>
      </BrowserRouter>
    </div>
  );
}

init();
console.log(process.env);
console.log(process.env.REACT_APP_CONSOLE_STARTUP);
