import React, { Component } from "react";

class Logo extends Component {
  render() {
    return (
      <div className="app-logo">
        <img
          src="/img/logo/all_res/unirooms_logo_624x195.png"
          alt="the application logo"
        />
      </div>
    );
  }
}

export default Logo;
