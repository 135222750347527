const root = document;
const head = root.head;
const body = root.body;
let isMobile = false;
let isIos = false;
let isMac = false;
let isWin = false;

export default function init() {
  isMobile = isMobileTest();
  isIos = isIosTest();
  isMac = isMacTest();
  isWin = isWinTest();
}

function isMobileTest() {
  let a = window.matchMedia("only screen and (max-width: 1024px)").matches;
  a = a && window.matchMedia("(any-pointer:coarse)").matches;
  a = a && "ontouchstart" in document.documentElement;

  if (a) root.getElementsByTagName("HTML")[0].classList.add("is-mobile");

  return a;
}

function isIosTest() {
  const iosQuirkPresent = function () {
    const audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes("Macintosh");
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  const a = isIos || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
  if (a) root.getElementsByTagName("HTML")[0].classList.add("is-ios");

  return a;
}

function isMacTest() {
  let a = navigator.platform.indexOf("Mac") > -1;

  if (a) root.getElementsByTagName("HTML")[0].classList.add("is-mac");

  return a;
}

function isWinTest() {
  let a = navigator.platform.indexOf("Win") > -1;

  if (a) root.getElementsByTagName("HTML")[0].classList.add("is-win");

  return a;
}
