import { useState } from "react";
import Header from "../components/Header";
import Cards from "../components/Cards";
import FullWidthGrid from "../components/grid";
import Example from "../components/example";
import DeleteIcon from "@mui/icons-material/Delete";
import Footer from "../components/Footer";

export default function Home() {
  const [selectedImng, setSelectedImg] = useState(null);

  return (
    <>
      <Header />
      <Cards setSelectedImg={setSelectedImg} />
      {/*<FullWidthGrid />*/}
      <Example />
      <DeleteIcon />
      <Footer />
    </>
  );
}
