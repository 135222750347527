import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_MAIN_COLOR,
    },
    secondary: {
      light: process.env.REACT_APP_BG_COLOR,
      main: process.env.REACT_APP_BG_COLOR,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2, // E.g., shift from Red 500 to Red 300 or Red 700.
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
