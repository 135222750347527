const Footer = () => {
  return (
    <footer className="footer">
      <section>
        <ul className="info-footer">
          <li>Acerca de</li>
          <li>Tienda virtual</li>
          <li>Contacto</li>
          <li>Trabaja con nosotros</li>
          <li>Política de cookies</li>
          <li>© 2021 Caribe Sstore, Enterprise.</li>
        </ul>

        <ul className="redes-footer">
          <li className="redes-li">
            <a href="https://web.whatsapp.com">WhatsApp</a>
          </li>
          <li className="redes-li">
            <a href="https://facebook.com">Facebook</a>
          </li>
          <li className="redes-li">
            <a href="https://instagram.com">Instagram</a>
          </li>
        </ul>
      </section>
    </footer>
  );
};

export default Footer;
