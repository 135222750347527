const Header = () => {
  return (
    <header>
      <div className="header-container">
        <div className="logo" alt="logo">
          <a href="/">
            CARIBE <br /> SSTORE
          </a>
        </div>
        <a href="/" className="caribe-sstore">
          CARIBE SSTORE
        </a>
        <section className="login-register-section">
          <a href="/login" className="signIn">
            Sign In
          </a>
          <a href="/register" className="signUp">
            Sign Up
          </a>
        </section>
      </div>
    </header>
  );
};

export default Header;
